import { createApp } from "vue";
import { getData } from "../util/fetchhandler.js";
import { Fancybox } from "@fancyapps/ui";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import ProductCataloguePublic from "../../components/ProductCataloguePublic.vue";

createApp({
  data() {
    return {
      loaded: false,
      topSales: [],
      onSales: []
    };
  },
  components: {
    productcatalogue: ProductCataloguePublic,
  },
  created() { },
  mounted() {
    this.getTopSales();
    this.getOnSales();
    Fancybox.bind("[data-fancybox]", {
      // Your custom options
    });

    this.$nextTick(() => {
      this.makeTopSalesTab();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.makeTopSalesTab();
    });
  },
  watch: {},
  computed: {},
  methods: {
    async getTopSales() {
      await getData(`${APP.BASEURL}/topsales`, false).then((response) => {
        this.topSales = response;
        this.setBulkPricesTopSales();
        this.loaded = true;
      });
    },
    async getOnSales() {
      await getData(`${APP.BASEURL}/onsales`, false).then((response) => {
        this.onSales = response;
        this.setBulkPricesOnSales();
        this.loaded = true;
      });
    },
    setBulkPricesTopSales() {
      this.topSales.categories.forEach((category) => {
        this.topSales.products[category.name].forEach((product) => {

          let default_bulk_price = {
            id: null,
            max_bulk: 1,
            allow_select_qty: 1,
            original_sale_price: Number(product.price),
            sale_price: Number(product.price),
            exchange_unit_sale_price: Number(product.price),
            unit_sale_price: Number(product.price),
            exchange_original_sale_price: Number(product.price),
            exchange_sale_price: Number(product.price),
            discount: Number(0)
          };

          let bulk_prices = JSON.parse(product.bulk_prices.substring(1, product.bulk_prices.length - 1));

          if (bulk_prices.length > 0) {
            bulk_prices.push(default_bulk_price);

            bulk_prices = bulk_prices.sort((a, b) => {
              return a.max_bulk - b.max_bulk;
            });

          }

          product.BulkPricingArray = bulk_prices;
          product.bulk_price_selected = default_bulk_price;
          // setBulkPrice(default_bulk_price);
        });
      })
    },
    setBulkPricesOnSales() {
      this.onSales.products.forEach((product) => {
        let default_bulk_price = {
          id: null,
          max_bulk: 1,
          allow_select_qty: 1,
          original_sale_price: Number(product.price),
          sale_price: Number(product.price_discount),
          exchange_unit_sale_price: Number(product.price_discount),
          unit_sale_price: Number(product.price_discount),
          exchange_original_sale_price: Number(product.price),
          exchange_sale_price: Number(product.price_discount),
          discount: Number(product.discount_percentage)
        };

        let bulk_prices = JSON.parse(product.bulk_prices.substring(1, product.bulk_prices.length - 1));

        if (bulk_prices.length > 0) {
          bulk_prices.push(default_bulk_price);

          bulk_prices = bulk_prices.sort((a, b) => {
            return a.max_bulk - b.max_bulk;
          });

        }

        product.BulkPricingArray = bulk_prices;
        product.bulk_price_selected = default_bulk_price;
      });
    },
    setBulkPrice(product, bulk_price) {
      product.bulk_price_selected = bulk_price;
    },
    makeTopSalesTab() {
      let _this = this;

      let $element = $("#so_listing_tabs_3"),
        $tab = $(".ltabs-tab", $element),
        $tab_label = $(".ltabs-tab-label", $tab),
        $tabs = $(".ltabs-tabs", $element),
        ajax_url = $tabs.parents(".ltabs-tabs-container").attr("data-ajaxurl"),
        effect = $tabs.parents(".ltabs-tabs-container").attr("data-effect"),
        delay = $tabs.parents(".ltabs-tabs-container").attr("data-delay"),
        duration = $tabs.parents(".ltabs-tabs-container").attr("data-duration"),
        type_source = $tabs
          .parents(".ltabs-tabs-container")
          .attr("data-type_source"),
        $items_content = $(".ltabs-items", $element),
        $items_inner = $(".ltabs-items-inner", $items_content),
        $items_first_active = $(".ltabs-items-selected", $element),
        $select_box = $(".ltabs-selectbox", $element),
        $tab_label_select = $(".ltabs-tab-selected", $element),
        setting = "2",
        type_show = "slider";

      this.enableSelectBoxes();

      /* First Ajax Listing tabs*/
      let el = $(".tab-sel", $element).attr("data-active-content"),
        el_active = $(el, $element);
      this.listing_tab_ajax(el_active);

      $("span.ltabs-tab-selected, span.ltabs-tab-arrow", $element).click(
        function () {
          if ($(".ltabs-tabs", $element).hasClass("ltabs-open")) {
            $(".ltabs-tabs", $element).removeClass("ltabs-open");
          } else {
            $(".ltabs-tabs", $element).addClass("ltabs-open");
          }
        }
      );

      $(window).resize(function () {
        if ($(window).innerWidth() <= 991) {
          $(".ltabs-tabs-wrap", $element).addClass("ltabs-selectbox");
        } else {
          $(".ltabs-tabs-wrap", $element).removeClass("ltabs-selectbox");
        }
      });

      $tab.on("click.ltabs-tab", function () {
        var $this = $(this);
        if ($this.hasClass("tab-sel")) return false;
        if ($this.parents(".ltabs-tabs").hasClass("ltabs-open")) {
          $this.parents(".ltabs-tabs").removeClass("ltabs-open");
        }
        $tab.removeClass("tab-sel");
        $this.addClass("tab-sel");
        var items_active = $this.attr("data-active-content");
        var _items_active = $(items_active, $element);
        $items_content.removeClass("ltabs-items-selected");
        _items_active.addClass("ltabs-items-selected");
        $tab_label_select.html(
          $tab.filter(".tab-sel").children(".ltabs-tab-label").html()
        );
        var $loading = $(".ltabs-loading", _items_active);
        var loaded = _items_active.hasClass("ltabs-items-loaded");

        type_show = $tabs
          .parents(".ltabs-tabs-container")
          .attr("data-type_show");
        if (!loaded && !_items_active.hasClass("ltabs-process")) {
          _items_active.addClass("ltabs-process");
          var category_id = $this.attr("data-category-id"),
            path_url = "/products/leading/category/",
            ajax_url = `${path_url}${category_id}`;

          $loading.show();
          _this.listing_tab_ajax(_items_active);
          // $('.ltabs-loading', _items_active).replaceWith(data);
          _items_active
            .addClass("ltabs-items-loaded")
            .removeClass("ltabs-process");
          $loading.remove();
          if (type_show != "slider") {
            _this.showAnimateItems(_items_active);
          }
          _this.updateStatus(_items_active);
        } else {
          if (type_show == "loadmore") {
            $(".ltabs-item", $items_content)
              .removeAttr("style")
              .addClass("new-ltabs-item");
            _this.showAnimateItems(_items_active);
          } else {
            var $tag_id = $element;
            var owl = $(".owl2-carousel", _items_active);
            var $navpage = $(".wap-listing-tabs", $tag_id);
            $navpage.siblings(".owl2-controls").addClass("hidden");

            owl = owl.data("owlCarousel2");

            if (typeof owl !== "undefined") {
              owl.onResize();
            }
          }
        }
      });
    },
    enableSelectBoxes() {
      let $element = $("#so_listing_tabs_3");
      let $tab_wrap = $(".ltabs-tabs-wrap", $element);
      let $tab_label_select = $(".ltabs-tab-selected", $element);

      $tab_label_select.html(
        $(".ltabs-tab", $element)
          .filter(".tab-sel")
          .children(".ltabs-tab-label")
          .html()
      );

      if ($(window).innerWidth() <= 991) {
        $tab_wrap.addClass("ltabs-selectbox");
      } else {
        $tab_wrap.removeClass("ltabs-selectbox");
      }
    },
    listing_tab_ajax(category_id) {
      let $element = $("#so_listing_tabs_3");
      let $tabs = $(".ltabs-tabs", $element);
      let $margin =
        $tabs.parents(".ltabs-tabs-container").attr("data-margin") ?? 30;
      let $col_lg = $tabs.parents(".ltabs-tabs-container").attr("data-lg");
      let $col_md = $tabs.parents(".ltabs-tabs-container").attr("data-md");
      let $col_sm = $tabs.parents(".ltabs-tabs-container").attr("data-sm");
      let $col_xs = $tabs.parents(".ltabs-tabs-container").attr("data-xs");
      var parent_active = $(category_id),
        total_product = parent_active.data("total"),
        tab_active = $(".ltabs-items-inner", parent_active),
        nb_column0 = $col_lg,
        nb_column1 = $col_md,
        nb_column2 = $col_sm,
        nb_column3 = $col_xs,
        nb_margin = parseInt($margin);

      if (tab_active.length > 0) {
        $(tab_active).owlCarousel({
          nav: false,
          dots: false,
          loop: false,
          autoplay: false,
          lazyLoad: true,
          autoplayHoverPause: true,
          autoplayTimeout: 5000,
          autoplaySpeed: 5000,
          navRewind: true,
          navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
          margin: nb_margin,
          responsive: {
            0: {
              items: nb_column3,
              nav: total_product <= nb_column3 ? false : true ? true : false,
            },
            767: {
              items: nb_column2,
              nav: total_product <= nb_column2 ? false : true ? true : false,
            },
            991: {
              items: nb_column1,
              nav: total_product <= nb_column1 ? false : true ? true : false,
            },
            1199: {
              items: nb_column0,
              nav: total_product <= nb_column0 ? false : true ? true : false,
            },
          },
        });
      }
    },
    showAnimateItems(el) {
      var $_items = $(".new-ltabs-item", el),
        nub = 0;
      $(".ltabs-loadmore-btn", el).fadeOut("fast");
      $_items.each(function (i) {
        nub++;
        switch (effect) {
          case "none":
            $(this).css({ opacity: "1", filter: "alpha(opacity = 100)" });
            break;
          default:
            animatesItems($(this), nub * delay, i, el);
        }
        if (i == $_items.length - 1) {
          $(".ltabs-loadmore-btn", el).fadeIn(3000);
        }
        $(this).removeClass("new-ltabs-item");
      });
    },
    animatesItems($this, fdelay, i, el) {
      var $_items = $(".ltabs-item", el);
      $this
        .stop(true, true)
        .attr(
          "style",
          "-webkit-animation:" +
          effect +
          " " +
          duration +
          "ms;" +
          "-moz-animation:" +
          effect +
          " " +
          duration +
          "ms;" +
          "-o-animation:" +
          effect +
          " " +
          duration +
          "ms;" +
          "-moz-animation-delay:" +
          fdelay +
          "ms;" +
          "-webkit-animation-delay:" +
          fdelay +
          "ms;" +
          "-o-animation-delay:" +
          fdelay +
          "ms;" +
          "animation-delay:" +
          fdelay +
          "ms;"
        )
        .delay(fdelay)
        .animate(
          {
            opacity: 1,
            filter: "alpha(opacity = 100)",
          },
          {
            delay: 1000,
          }
        );
      if (i == $_items.length - 1) {
        $(".ltabs-items-inner").addClass("play");
      }
    },
    updateStatus($el) {
      $(".ltabs-loadmore-btn", $el).removeClass("loading");
      var countitem = $(".ltabs-item", $el).length;
      $(".ltabs-image-loading", $el).css({ display: "none" });
      $(".ltabs-loadmore-btn", $el).parent().attr("data-rl_start", countitem);
      var rl_total = $(".ltabs-loadmore-btn", $el)
        .parent()
        .attr("data-rl_total");
      var rl_load = $(".ltabs-loadmore-btn", $el).parent().attr("data-rl_load");
      var rl_allready = $(".ltabs-loadmore-btn", $el)
        .parent()
        .attr("data-rl_allready");

      if (countitem >= rl_total) {
        $(".ltabs-loadmore-btn", $el).addClass("loaded");
        $(".ltabs-image-loading", $el).css({ display: "none" });
        $(".ltabs-loadmore-btn", $el).attr("data-label", rl_allready);
        $(".ltabs-loadmore-btn", $el).removeClass("loading");
      }
    },
    setAltImg(event) {
      event.target.src = '/dist/intermotos/images/logo_not_image.png';
    },
    formatterCurrency(val) {
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
      }).format(val);
    }
  },
})
  .use()
  .mount("#app");
